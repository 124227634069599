<template>
  <div class="register">
    <div class="background" :class="{ 'background-success': isSuccess }">
      <div class="container">
        <div class="columns" v-if="!isSuccess">
          <div class="column is-half is-offset-one-quarter has-text-centered">
            <div class="title-login">
              <p><b>{{ $t('REGISTER.CREATE') }}</b></p>
            </div>
          </div>
        </div>
        <div class="columns" v-if="isSuccess">
          <div class="column is-half is-offset-one-quarter" style="padding-top: 10rem;">
            <MessageBox type="success" :title="$t('REGISTER.TITLE')" :subtitle="$t('REGISTER.SUBTITLE')" />
          </div>
        </div>
        <div class="columns" v-if="!isSuccess">
          <div class="column is-8 is-offset-2">
            <form @submit.prevent="validateBeforeSubmit">
              <div class="login-auth">
                <div class="columns">
                  <div class="column is-10 is-offset-1">
                    <b-notification :active.sync="isActive" aria-close-label="Close notification" class="noti-register"
                      auto-close :duration="5000" :closable="false">
                      <font-awesome-icon :icon="['far', 'info-circle']" />
                      <span style="padding-left: .5rem;">{{ $t('REGISTER.ERROR_REGISTER') }}</span>
                    </b-notification>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-5 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('nombre') }">
                      <b-input v-validate="'required'" name="nombre" v-model.trim="registerForm.nombre"
                        :placeholder="$t('REGISTER.NAME')" custom-class="custom-input-i" icon-pack="fal" icon="user">
                      </b-input>
                    </b-field>
                    <span v-if="errors.has('nombre')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.NAME')}) }}</span>
                  </div>
                  <div class="column is-5">
                    <b-field :type="{ 'is-danger': errors.has('primer_apellido') }">
                      <b-input v-validate="'required'" name="primer_apellido"
                        v-model.trim="registerForm.primer_apellido" :placeholder="$t('REGISTER.SURNAME')"
                        custom-class="custom-input-i" icon-pack="fal" icon="user"></b-input>
                    </b-field>
                    <span v-if="errors.has('primer_apellido')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.SURNAME')}) }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-5 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('segundo_apellido') }">
                      <b-input v-validate="'required'" name="segundo_apellido"
                        v-model.trim="registerForm.segundo_apellido" :placeholder="$t('REGISTER.SECOND_SURNAME')"
                        custom-class="custom-input-i" icon-pack="fal" icon="user"></b-input>
                    </b-field>
                    <span v-if="errors.has('segundo_apellido')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.SECOND_SURNAME')}) }}</span>
                  </div>
                  <div class="column is-5">
                    <b-field :type="{ 'is-danger': errors.has('email') }">
                      <b-input v-validate="'required|email'" name="email" v-model.trim="registerForm.email"
                        :placeholder="$t('REGISTER.EMAIL')" custom-class="custom-input-i" icon-pack="fal"
                        icon="envelope"></b-input>
                    </b-field>
                    <span v-if="errors.has('email')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.EMAIL')}) }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-5 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('comunidad_autonoma') }">
                      <b-select v-validate="'required'" name="comunidad_autonoma"
                        :placeholder="$t('REGISTER.COMMUNITY')" v-model.trim="registerForm.comunidad_autonoma"
                        icon="map-marked" icon-pack="fal" @input="selectCommunity">
                        <option v-for="community in communitys" :key="community.id" :value="community.id">
                          {{ community.name }}</option>
                      </b-select>
                    </b-field>
                    <span v-if="errors.has('comunidad_autonoma')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.COMMUNITY')}) }}</span>
                  </div>
                  <div class="column is-5">
                    <b-field :type="{ 'is-danger': errors.has('province') }">
                      <b-select v-validate="'required'" name="provincia" :placeholder="$t('REGISTER.PROVINCE')"
                        v-model.trim="registerForm.provincia" icon="map-marked" icon-pack="fal" @input="selectProvince">
                        <option v-for="province in provinces" :key="province.id" :value="province.id">
                          {{ province.name }}</option>
                      </b-select>
                    </b-field>
                    <span v-if="errors.has('provincia')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.PROVINCE')}) }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-5 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('password') }">
                      <b-input v-validate="{
                          required: true,
                          regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.*){8,}$/,
                          min: 8,
                        }" name="password" v-model.trim="registerForm.password" :placeholder="$t('REGISTER.PASSWORD')"
                        type="password" ref="password" custom-class="custom-input-i" icon-pack="fal" icon="lock-alt">
                      </b-input>
                    </b-field>
                    <span v-if="errors.has('password')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_PASSWORD', {field: $t('REGISTER.PASSWORD')}) }}</span>
                  </div>
                  <div class="column is-5">
                    <b-field :type="{ 'is-danger': errors.has('repeat password') }">
                      <b-input v-validate="'required|confirmed:password'" name="repeat password"
                        v-model.trim="registerForm.confirmPassword" :placeholder="$t('REGISTER.CONFIRM_PASSWORD')"
                        type="password" custom-class="custom-input-i" icon-pack="fal" icon="lock-alt"></b-input>
                    </b-field>
                    <span v-if="errors.has('repeat password')"
                      class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.CONFIRM_PASSWORD')}) }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-5 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('telefono') }">
                      <b-input v-validate="{
                          regex: /\d{9}/,
                          min: 9,
                          max: 9
                        }" name="telefono" v-model.trim="registerForm.telefono"
                        :placeholder="$t('REGISTER.PHONE')" custom-class="custom-input-i" icon-pack="fal" icon="phone"
                        type="tel" pattern="[0-9]{9}"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1">
                        <b-field :type="{ 'is-danger': !registerForm.centro && this.registerForm.comunidad_autonoma && this.registerForm.provincia }" :message="$t('REGISTER.IF_NOT_FOUND')">
                          <b-autocomplete
                              custom-class="custom-input-i"
                              :data="filteredCenters"
                              field="name"
                              v-model="filterName"
                              :placeholder="$t('REGISTER.CENTER')"
                              icon="school" icon-pack="fal"
                              clearable
                              open-on-focus
                              @select="option => registerForm.centro = (option ? option.id : null)">
                              <template #empty>{{$t('REGISTER.NO_RESULTS')}}</template>
                          </b-autocomplete>
                      </b-field>

                      <span v-if="!registerForm.centro && this.registerForm.comunidad_autonoma && this.registerForm.provincia"
                        class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.CENTER')}) }}</span>
                  </div>
                </div>
                <div class="columns" v-if="registerForm.centro === -1">
                  <div class="column is-10 is-offset-1">
                    <b-field :type="{ 'is-danger': errors.has('centro_nuevo') }">
                      <b-input v-validate="'required'" name="centro_nuevo" v-model.trim="registerForm.centro_nuevo"
                        :placeholder="$t('REGISTER.CENTER')" custom-class="custom-input-i" icon-pack="fal"
                        icon="school"></b-input>
                      <span v-if="errors.has('centro_nuevo')"
                        class="help is-danger">{{ $t('REGISTER.ERROR_REQUIRED', {field: $t('REGISTER.CENTER')}) }}</span>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1">
                    <p class="argument-caixa gray">
                      {{ $t('REGISTER.GDPR_INFO') }}
                    </p>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1">
                    <div class="field field-checkbox">
                      <input class="is-checkselectCommunityradio is-small" :message="{
                          'Please understood and accept the Personal data processing': errors.firstByRule(
                            'personal data processing',
                            'required'
                          ),
                        }" v-validate="'required:false'" v-model="registerForm.isGdprAccepted"
                        name="personal data processing" id="checkPDP" type="checkbox" />
                      <label for="checkPDP" class="gray" v-html="$t('REGISTER.GDPR_CHECK')"></label>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1 column-button">
                    <button class="button btn-login">
                      <span><b>{{ $t('REGISTER.SING_IN') }}</b></span>
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1 has-text-centered">
                    <hr />
                    <router-link to="/login"><span class="text-m text-primary" v-html="$t('REGISTER.HAVE_ACCOUNT')"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MessageBox from "@/components/MessageBox.vue";

  export default {
    name: "registro",
    components: {
      MessageBox,
    },
    data() {
      return {
        registerForm: {
          nombre: null,
          primer_apellido: null,
          segundo_apellido: null,
          email: null,
          password: null,
          confirmPassword: null,
          comunidad_autonoma: null,
          provincia: null,
          centro: null,
          centro_nuevo: null,
          telefono: null,
          isGdprAccepted: false,
        },
        communitys: [],
        provinces: [],
        centers: [],
        filterName: "",
        isSuccess: false,
        isActive: false,
      };
    },
    methods: {
      validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
          if (result) {
            const data = {
              nombre: this.registerForm.nombre,
              primer_apellido: this.registerForm.primer_apellido,
              segundo_apellido: this.registerForm.segundo_apellido,
              email: this.registerForm.email,
              password: this.registerForm.password,
              telefono: this.registerForm.telefono,
              comunidad_autonoma: this.registerForm.comunidad_autonoma,
              provincia: this.registerForm.provincia,
              centro: this.registerForm.centro,
              centro_nuevo: this.registerForm.centro_nuevo,
            };

            this.$store.commit("setShowSpinner", true);
            this.$store
              .dispatch("register", {
                data,
              })
              .then((user) => {
                this.$store.commit("setShowSpinner", false);
                this.isSuccess = true;
              })
              .catch((error) => {
                this.isActive = true;
                this.$store.commit("setShowSpinner", false);
              });
          }
        });
      },
      selectCommunity() {
        this.provinces = this.communitys.find(
          (c) => c.id === this.registerForm.comunidad_autonoma
        ).provinces;

        this.registerForm.provincia = null;
        this.registerForm.centro = null;
        this.registerForm.centro_nuevo = null;
      },
      selectProvince() {
        this.centers = this.communitys.find(
          (c) => c.id === this.registerForm.comunidad_autonoma
        ).provinces.find((p) => p.id === this.registerForm.provincia).centros;

        this.registerForm.centro = null;
        this.registerForm.centro_nuevo = null;
      },
    },
    computed: {
        filteredCenters() {
          const centers = this.centers.filter((c) => {
            return (
                c.name
                    .toString()
                    .toLowerCase()
                    .indexOf(this.filterName.toLowerCase()) >= 0
            )
          });

          if(this.registerForm.comunidad_autonoma !== null && this.registerForm.provincia !== null) {
            centers.push({name: this.$t('REGISTER.CENTER_NOT_FOUNT'), id: -1});
          }

          return centers;
        }
    },
    created() {
    this.$store.commit("setShowSpinner", true);
      this.$store
        .dispatch("getRegisterForm")
        .then((data) => {
          this.communitys = data;
          this.$store.commit("setShowSpinner", false);
        })
      .catch((error) => {
        this.$store.commit("setShowSpinner", false);
      });
  },
  };
</script>

<style scoped lang="scss">
  @import "../styles/variables.scss";

  .btn-login {
    background-color: $primary;
    color: white;
    height: 60px;
    width: 100%;
    border-radius: 10px;
  }

  .btn-login:hover {
    color: white;
  }

  .background {
    background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
    min-height: calc(100% + 40px);
  }

  .background-success {
    background-attachment: none;
    position: absolute;
  }

  .background:before {
    content: " ";
    position: absolute;
    top: 0;
    z-index: 0;

    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
  }

  .title-login {
    color: white;
    font-size: 2rem;
    padding-top: 3rem;
  }

  .login-auth {
    background-color: white;
    border-radius: 0.1rem;
    padding: 3.5rem 0;
    margin-bottom: 2rem;
  }

  .column-button {
    padding-top: 2.5rem;
    margin-bottom: -0.5rem;
  }

  .argument-caixa {
    font-size: 0.8rem;
    font-style: italic;
  }

  .media-content {
    color: red !important;
  }

  .quadre-info {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    border: 1px solid #302783;
    font-size: 0.95rem;
    color: #302783;
  }

  .quadre-info .paragraf {
    padding-top: 1rem;
    font-size: 0.95rem;
  }

  .field-checkbox {
    display: flex;
    align-items: center;

    label {
      padding-left: 8px;
    }
  }
</style>